<template>
	<div class="top position-relative">
		<div
			class="logo-position"
			@click="toMain"
			:style="bg_logo"
		>
			<img :src="require('@/assets/' + $logo)" class="width-70">
		</div>

		<button
			v-if="false"
			class="position-absolute "
			style="right: 10px; top: 30px;"

			@click="onSide"
		><v-icon class="">mdi-menu</v-icon></button>
	</div>
</template>

<script>
	export default{
		name: 'Top'
		, props: ['user', 'seller_info', 'program']
		, computed: {
			title: function(){
				let t = process.env.VUE_APP_NAME
				if(this.program.title){
					t = this.program.title
				}
				return t
			}
			, bg_logo: function(){
				let t = ''
				if(this.program.shop_logo){
					t = 'background-image: url(' + this.program.shop_logo + ');'
				}
				return t
			}
		}
		, methods: {
			onSide: function(){
				this.$emit('toggleSide')
			}
			, toMain: function(){
				this.$bus.$emit('to', {name: 'Index', not_query: false})
			}
		}
		, created() {

		}
	}
</script>

<style>
	.top {
		background-color: var(--bg-white);
		color: var(--color-eee);
		font-size: var(--size-px-16);
		font-weight: var(--weight-600);
		border-bottom: 1px solid #ddd
	}
	.shop-logo { max-width: 300px; max-height: 100px }
</style>