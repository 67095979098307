
import { language } from '@/resources/lang/kr/common'
import {Axios} from "@/resources/axios/axios";

export const request = {
	language: language,
	Axios: Axios,

	upload_url: function(file_name){
		//console.log('file_name', file_name)
		if(!file_name){
			return ''
		}
		if(file_name.indexOf('https') > -1){
			return file_name
		}

		if(file_name.indexOf('data:image') > -1){
			return file_name
		}
	},

	init: async function({ method, url, data, header, authorize, multipart, TOKEN, blob, isResult }){
		try {
			return Axios({ method, url, data, header, authorize, multipart, TOKEN, blob, isResult })
		}catch (e) {
			// console.log('init', e)
			return { success: false, message: e}
		}
	}
	, set: function(obj, arg){

		let t = ''
		if(obj){
			t = obj[arg]
		}
		return t
	}
}
