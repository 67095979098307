<template>
	<PopupLayer
		:is_full="true"
	>
		<template
			v-slot:body
		>
			<div class="flex-column full-height overflow-y-auto bg-white ">

				<div class="pa-10 justify-space-between bg-identify">
					<h6>SMS 인증</h6>
					<button
						v-if="user.sms_auth_uid"
						@click="$emit('cancel')"
					><v-icon class="color-eee">mdi-close-circle</v-icon></button>
				</div>

				<div class="flex-column justify-center full-height bg-white size-px-14 font-weight-500">

					<div class="size-px-24 font-weight-bold">

						<div
							v-if="item.is_on_sms_confirm"
							class="pa-10"
						>
							<input
								v-model="item.sms_code"
								type="number" placeholder="인증코드를 입력하세요"
								class="pa-10 text-center size-px-24 font-weight-bold"
								:rules="[$rules.max(item, 'sms_code', 6)]"
							/>
						</div>

						<div
							v-else
							class="pa-10 text-center"
						>SMS 문자인증</div>

					</div>
				</div>

				<div
					class="mt-auto"
				>
					<button
						v-if="item.is_on_sms_confirm"
						class="btn btn-primary"
						@click="postSmsConfirm"
						:disabled="is_on_sms_confirm_button"
					>SMS 인증 확인</button>
					<button
						v-else
						@click="postSmsAuth"
						class="btn btn-primary"
						:disabled="is_sms_auth"
					>인증요청</button>
				</div>
			</div>
		</template>
	</PopupLayer>
</template>

<script>

import PopupLayer from "./PopupLayer";
export default{
	name: 'SmsConfirm'
	, components: {PopupLayer}
	, props: ['user', 'type']
	, data: function(){
		return {
			program: {
				name: 'SMS 인증'
				,top: false
				,title: true
				,bottom: false
				,class: 'blue'
			}
			,item: {
				sms_confirm: false
				, sms_code: ''
				, is_use_sms_auth: true
				, is_on_sms_confirm: false
				, is_sms_confirm: false
				, type: this.type
			}
		}
	}
	, computed: {

		is_sms_auth: function(){
			let t = true
			if(!this.item.is_on_sms_confirm && this.user.account_phone_number){
				t = false
			}
			return t
		}
		, is_on_sms_confirm_button: function(){
			let t = true
			if(this.item.sms_code.length == 6 && this.item.is_on_sms_confirm){
				t = false
			}
			return t
		}
	}
	, methods: {
		resetSmsAuth: function(){
			this.item.is_on_sms_confirm = false
			this.item.is_sms_confirm = false
			this.item.sms_code = ''
		}
		, postSmsAuth: async function(){
			try{
				this.item.is_on_sms_confirm = false
				let result = await this.$Axios({
					method: 'post'
					, url: '/member/postSmsAuth'
					, data: {
						type: this.type
					}
				})
				if(result.success){
					this.item.is_on_sms_confirm = true
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message })
				}
			}catch(e){
				console.log(e.message)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}
		}
		, postSmsConfirm: async function(){
			try{
				let result = await this.$Axios({
					method: 'post'
					,url: '/member/postSmsConfirm'
					,data: {
						sms_code: this.item.sms_code
						, type: this.type
					}
				})
				if(result.success){
					this.item.is_sms_confirm = true
					this.$emit('click', this.item)
				}else{
					throw result.message
				}
			}catch(e){
				this.resetSmsAuth()
				this.$bus.$emit('notify', { type: 'error', message: e})
			}
		}
	}
	,created: function(){
		this.$emit('onLoad', this.program)
	}
}

</script>

<style>
.content input[type=text] { width: 100%; border-bottom: 1px solid #ddd; padding: 10px 0;}
</style>