

export const metaInfo = {
	title: '딜리몰'
	,meta: [
		{
			name: 'viewport'
			,content: 'width=device-width,initial-scale=1.0,user-scalable=no'
		}
		, {
			charset: 'utf-8'
		}
	]
}