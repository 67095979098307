<template>
	<div
		class="bottom justify-space-between text-center box-shadow-top position-relative bg-base position-relative"
	>
		<span
			class="flex-1 ptb-10 flex-column"
			:class="{on: is_home}"
			@click="toHome"
		>
			<v-icon
				class="color-base"
			>mdi mdi-home-outline</v-icon>
		</span>

		<span
			class="flex-1 ptb-10 flex-column"
			@click="toCalculate"
			:class="{on: is_calculate}"
		>
			<v-icon
				class="color-base"
			>mdi mdi-file-document</v-icon>
		</span>
		<span
			class="flex-1 ptb-10 flex-column position-relative"
			@click="toWallet"
			:class="{on: is_wallet}"
		>
			<v-icon
				class="color-base"
			>mdi mdi-wallet</v-icon>
		</span>
		<span
			class="flex-1 ptb-10 flex-column"
			@click="onSide"
			:class="{on: is_account}"
		>
			<v-icon
				class="color-base"
			>mdi mdi-account</v-icon>
		</span>
	</div>
</template>

<script>
export default{
	name: 'BottomPayment'
	,props: ['cart_cnt']
	,data: function(){
		return {
		}
	}
	,computed: {
		is_home: function(){
			if(this.$route.path == '/Index'){
				return true
			}else{
				return false
			}
		}
		, is_wallet: function(){
			if(this.$route.path.indexOf('/Wallet') > -1){
				return true
			}else{
				return false
			}
		}
		,is_calculate: function(){
			if(this.$route.path.indexOf('/Payment') > -1){
				return true
			}else{
				return false
			}
		}
		, is_account: function(){

			if(this.$route.path.indexOf('/MyPage') > -1){
				return true
			}else{
				return false
			}
		}
	}
	,methods: {
		toHome: function(){
			this.$bus.$emit('to', { name: 'Index', hash: 'payment'})
		}
		, toCalculate: function(){
			this.$bus.$emit('to', { name: 'PaymentList', hash: 'payment'})
		}
		, toWallet: function(){
			alert('준비중입니다.')
			// this.$bus.$emit('to', { name: 'WalletList'})
		}
		, onSide: function(){
			this.$bus.$emit('to', { name: 'MyPage', hash: 'payment'})
		}
	}
}
</script>

<style>

.bottom .on { background-color: #eee; color: #fefefe;}
.bottom .on i { color: #333 !important;}
</style>