import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';
import VueDaumPostcode from "vue-daum-postcode"
import { storage } from "./resources/storage/storage"
import { common } from '@/assets/js/common'
import {Axios} from "@/resources/axios/axios";
import Loading from "@/view/Layout/Loading"
import Notify from "@/components/AlertMsg"
import Modal from "./components/Modal";
import { rules } from "@/resources/rules/rules"
import { date } from "@/resources/date/date"
import filter from '@/resources/filter/filter'
import { api_url } from '@/resources/axios/api_url'
import { request } from "./resources/request/request";


Vue.config.productionTip = false

Vue.use(VueDaumPostcode)


if(window.location.href.indexOf(process.env.VUE_APP_DOMAIN) > -1){
  Vue.prototype.$production = true
  Vue.prototype.$production_title = process.env.VUE_APP_TITLE
}else{
  Vue.prototype.$production = false
  Vue.prototype.$production_title = process.env.VUE_APP_TITLE_DEV
}

Vue.prototype.$Axios = Axios
Vue.prototype.$Loading = Loading
Vue.prototype.$Notify = Notify
Vue.prototype.$Modal = Modal
Vue.prototype.$rules = rules
Vue.prototype.$date = date
Vue.prototype.$filter = filter
Vue.prototype.$storage = storage
Vue.prototype.$pdt_img_url = ''
Vue.prototype.$server_url = process.env.VUE_APP_SERVER
Vue.prototype.$common = common
Vue.prototype.$api_url = api_url
Vue.prototype.$request = request


Vue.prototype.$bus = new Vue()

Vue.prototype.$shop_logo = '/images/' + process.env.VUE_APP_SHOP + '/' + process.env.VUE_APP_LOGO

let location = window.location.hostname
if(location == 'changemoney.shop'){
  Vue.prototype.$logo = "logo.png"
}else{
  Vue.prototype.$logo = "logo_.png"
}
Vue.prototype.$host = location
Vue.prototype.$https = 'https://' + location

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
