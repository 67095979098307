<template>
	<transition
		name="fade"
	>
		<div
			v-if="show"
			class="modal-wrap"
		>
			<div
				class="modal-bg"
			></div>
			<div
				class="modal-container"
			>
				<div
					class="modal-content"
				>
					<div
						v-if="options.top"
						class="modal-top"
					>
						<slot name="modal-title">
							<strong class="size-px-16">{{ options.title }}</strong>
							<button
								v-if="!options.close"
								class="modal-btn-close"
								@click="close"
							><v-icon>mdi-close-circle</v-icon></button>
						</slot>
					</div>

					<div
						class="modal-contents"
					><slot name="modal-content">{{ options.content }}</slot></div>
				</div>

				<div
					v-if="options.bottom"
					class="modal-bottom bg-white"
				><slot name="modal-bottom">{{ options.bottom }}</slot></div>
				<div
					v-if="options.to"
					class="modal-bottom "
				><button v-if="options.to" class="btn btn-identify" @click="to">확인</button></div>
			</div>
		</div>
	</transition>
</template>

<script>
export default {
	name: 'modal'
	,props: ['option', 'is_modal']
	,data: function(){
		return {
			show: this.is_modal
			, options: this.option
		}
	}
	,methods: {
		close: function(){
			this.show = false
			this.$emit('close')
		}
		, to: function(){
			this.$bus.$emit('to', { name: this.options.to, type: 'href'})
		}
	}

	,created() {
		this.$bus.$on('onModal', (option) => {
			this.options = option
			this.show = true
		})
	}
	,watch: {
		option: {
			deep: true
			,handler: function(call){
				this.options = call
				this.show = true
			}
		}
		,is_modal: {
			handler: function(call){
				this.show = call
			}
		}
	}
}
</script>

<style>
.modal-wrap { position: fixed; top: 0; left: 0; z-index: 999999999; width: 100%; height: 100%;}
.modal-wrap .modal-bg {position: absolute; width: 100%; height: 100%; background: black; opacity: 0.5;}
.modal-wrap .modal-container { position: relative; z-index: 998; padding: 20px; display: flex; flex-direction: column; justify-content: center; height: 100%; max-width: 480px; margin: 0 auto;}
.modal-wrap .modal-container .modal-content {width: 100%;  background-color: white; padding: 0; border-radius: 5px 5px 0 0;
	overflow: auto;  display: flex; flex-direction: column; }
.modal-wrap .modal-container .modal-content .modal-top { padding: 10px; border-bottom: 1px solid #bbb;}
.modal-wrap .modal-container .modal-content .modal-contents {min-height: 80px; max-height: 100%; padding: 10px; overflow: auto;}
.modal-wrap .modal-container .modal-content .modal-bottom { margin-top: auto; padding: 0; }
.modal-btn-close { float: right; padding: 0; height: auto;}

.modal-wrap .modal-container .modal-bottom { border-radius: 0 0 5px 5px}

.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
	opacity: 0;
}
</style>

