import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const prefix = '/'

const routes = [
	
	{
		path: '/'
		,name: 'intro'
		,component: () => import('@/view/Main/Intro.vue')
	}
	,{
		path: prefix + 'Index'
		,name: 'Index'
		,component: () => import('@/view/Main/Index.vue')
	}
	,{
		path: prefix + 'Auth/Login'
		,name: 'Login'
		,component: () => import('@/view/Auth/Login.vue')
	}
	,{
		path: prefix + 'Auth/Login/Shop/:shop_id'
		,name: 'ShopLogin'
		,component: () => import('@/view/Auth/ShopLogin.vue')
	}
	,{
		path: prefix + 'Auth/Find/Id'
		,name: 'FindId'
		,component: () => import('@/view/Auth/FindId.vue')
	}
	,{
		path: prefix + 'Auth/Find/Pw'
		,name: 'FindPw'
		,component: () => import('@/view/Auth/FindPw.vue')
	}
	,{
		path: prefix + 'Auth/Join/:code?'
		,name: 'Join'
		,component: () => import('@/view/Auth/Join.vue')
	}
	,{
		path: prefix + 'Auth/Result'
		,name: 'JoinResult'
		,component: () => import('@/view/Auth/Result.vue')
	}
	,{
		path: prefix + 'Product/List/:category?'
		,name: 'ProductList'
		,component: () => import('@/view/Product/ProductList.vue')
	}
	,{
		path: prefix + 'Product/Detail/:idx'
		,name: 'ProductDetail'
		,component: () => import('@/view/Product/ProductDetail.vue')
	}
	,{
		path: prefix + 'Order/Cart'
		,name: 'Cart'
		,component: () => import('@/view/Order/Cart.vue')
	}
	,{
		path: prefix + 'Order/Buy'
		,name: 'OrderBuy'
		,component: () => import('@/view/Order/OrderForm.vue')
	}
	,{
		path: prefix + 'Order/Bill'
		,name: 'OrderBill'
		,component: () => import('@/view/Order/OrderForm.vue')
	}
	,{
		path: prefix + 'Order/Form'
		,name: 'OrderForm'
		,component: () => import('@/view/Order/OrderForm.vue')
	}
	,{
		path: prefix + 'Order/Result/:order_num_new'
		,name: 'OrderResult'
		,component: () => import('@/view/Order/OrderResult.vue')
	}
	,{
		path: prefix + 'Order/Detail/:order_num_new'
		,name: 'OrderDetail'
		,component: () => import('@/view/Order/OrderDetail.vue')
	}
	,{
		path: prefix + 'Order/List/:type/:page?'
		,name: 'OrderList'
		,component: () => import('@/view/Order/OrderList.vue')
	}
	,{
		path: prefix + 'Order/Regular'
		,name: 'OrderRegular'
		,component: () => import('@/view/Order/OrderRegularList.vue')
	}
	,{
		path: prefix + 'Order/Regular/Detail/:idx'
		,name: 'OrderRegularDetail'
		,component: () => import('@/view/Order/OrderRegularDetail.vue')
	}
	,{
		path: prefix + 'Order/Odt/Confirm/:odt_uid'
		,name: 'OdtConfirm'
		,component: () => import('@/view/Order/OdtConfirm.vue')
	}
	,{
		path: prefix + 'Order/Odt/Return/:odt_uid'
		,name: 'OdtReturn'
		,component: () => import('@/view/Order/OdtReturn.vue')
	}
	,{
		path: prefix + 'Account/MyPage'
		,name: 'MyPage'
		,component: () => import('@/view/Account/MyPage.vue')
	}
	,{
		path: prefix + 'Account/Modify'
		,name: 'Modify'
		,component: () => import('@/view/Account/Modify.vue')
	}
	,{
		path: prefix + 'Account/Password'
		,name: 'Password'
		,component: () => import('@/view/Account/Password.vue')
	}
	,{
		path: prefix + 'Account/Shipping'
		,name: 'Shipping'
		,component: () => import('@/view/Account/ShippingList.vue')
	}
	,{
		path: prefix + 'Account/SmsConfirm'
		,name: 'SmsConfirmRequest'
		,component: () => import('@/view/Account/AccountSmsConfirm.vue')
	}
	,{
		path: prefix + 'Credit'
		,name: 'CreditList'
		,component: () => import('@/view/Account/CreditList.vue')
	}
	,{
		path: prefix + 'Credit/Register'
		,name: 'CreditRegister'
		,component: () => import('@/view/Account/CreditRegister.vue')
	}
	,{
		path: prefix + 'CustomerCenter/:b_code'
		,name: 'CustomerCenter'
		,component: () => import('@/view/Customer/CustomerCenter.vue')
	}
	,{
		path: prefix + 'Bbs/Item/:b_code'
		,name: 'BbsItem'
		,component: () => import('@/view/Bbs/BbsItem.vue')
	}
	,{
		path: prefix + 'Wallet'
		,name: 'WalletIndex'
		,component: () => import('@/view/Wallet/WalletIndex')
	}
	,{
		path: prefix + 'Wallet/List'
		,name: 'WalletList'
		,component: () => import('@/view/Wallet/WalletList')
	}
	,{
		path: prefix + 'Wallet/Withdrawal/:shop_uid'
		,name: 'WalletWithdrawal'
		,component: () => import('@/view/Wallet/WalletWithdrawal')
	}
	,{
		path: prefix + 'Wallet/History'
		,name: 'WalletHistory'
		,component: () => import('@/view/Wallet/WalletHistory')
	}
	,{
		path: prefix + 'Wallet/DepositHistory'
		,name: 'WalletDepositHistory'
		,component: () => import('@/view/Wallet/WalletDepositHistory')
	}
	,{
		path: prefix + 'Calculate/List/:date?'
		,name: 'CalculateList'
		,component: () => import('@/view/Calculate/CalculateList')
	}
	,{
		path: prefix + 'Calculate/Item/:date?'
		,name: 'CalculateItem'
		,component: () => import('@/view/Calculate/CalculateItem')
	}
	,{
		path: prefix + 'Calculate/Api/:date?'
		,name: 'CalculateApi'
		,component: () => import('@/view/Calculate/CalculateApi')
	}
	,{
		path: prefix + 'Franchisee/List'
		,name: 'Franchisee'
		,component: () => import('@/view/Franchisee/FranchiseeList')
	}
	,{
		path: prefix + 'Franchisee/Item'
		,name: 'FranchiseeItem'
		,component: () => import('@/view/Franchisee/FranchiseeItem')
	}
	,{
		path: prefix + 'Payment/Agree/:shop_uid'
		,name: 'PaymentAgree'
		,component: () => import('@/view/Payment/PaymentAgree')
	}
	,{
		path: prefix + 'Payment/Item/:shop_uid'
		,name: 'PaymentItem'
		,component: () => import('@/view/Payment/PaymentItem')
	}
	,{
		path: prefix + 'Payment/Detail/:idx'
		,name: 'PaymentDetail'
		,component: () => import('@/view/Payment/PaymentDetail')
	}
	,{
		path: prefix + 'Payment/Order/:idx'
		,name: 'PaymentOrder'
		,component: () => import('@/view/Payment/PaymentOrder')
	}
	,{
		path: prefix + 'Payment/List/:page?'
		,name: 'PaymentList'
		,component: () => import('@/view/Payment/PaymentList')
	}
	,{
		path: prefix + 'Virtual/Index'
		,name: 'VirtualIndex'
		,component: () => import('@/view/Virtual/VirtualIndex')
	}

	,{
		path: prefix + 'Virtual/History'
		,name: 'VirtualHistory'
		,component: () => import('@/view/Virtual/VirtualHistory')
	}
	,{
		path: '*',
		name:'404', 
		component: () => import('@/view/Error/NotFound.vue')
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

router.beforeEach((to, from, next) => {
	// console.log('from: ' + from.path + ' to : ' + to.path)
	next();
});

export default router
